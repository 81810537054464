import { defineStore } from 'pinia';

type ArticleTypesArray = {
    id: string;
    title: string;
}

export const useArticleStore = defineStore('article-store', {
    state: () => ({
        articleTypes: [] as ArticleTypesArray[]
    })
});
