export default [
    {
        id: 1, // Used for filtering
        key: 'algemeen-bestuur',
        name: 'Algemeen Bestuur'
    },
    {
        id: 2, // Used for filtering
        key: 'regiobestuursvergadering',
        name: 'Regiobestuursvergadering',
    },
    {
        id: 3, // Used for filtering
        key: 'bijeenkomst-algemeen',
        name: 'Bijeenkomst algemeen',
    },
    {
        id: 4, // Used for filtering
        key: 'themabijeenkomst',
        name: 'Themabijeenkomst',
    },
    // {
    //     id: 5, // Used for filtering
    //     key: 'opleidingen',
    //     name: 'Opleidingen',
    // },
    // {
    //     id: 6, // Used for filtering
    //     key: 'opleidingen-consultancy',
    //     name: 'Opleidingen (Consultancy)',
    // },
    // {
    //     id: 7, // Used for filtering
    //     key: 'workshop',
    //     name: 'Workshop',
    // },
    {
        id: 8, // Used for filtering
        key: 'vergadering',
        name: 'Vergadering'
    }
];
