import { useGtm } from '@gtm-support/vue-gtm';

export default function useFilters(queryParam) {
    const router = useRouter();
    const route = useRoute();

    const activeValues = computed(() => {
        const queryParamString = route.query[queryParam];

        // No values
        if (!queryParamString) {
            return [];
        }

        // Only 1 value
        if (!queryParamString.includes(',')) {
            return [parseInt(queryParamString, 10)];
        }

        return queryParamString.split(',').map(queryValue => parseInt(queryValue, 10));
    });

    const isActive = function(value) {
        return toValue(activeValues).includes(parseInt(value, 10));
    };

    const activateFilter = function(value) {
        if (isActive(value)) {
            return;
        }

        useGtm()?.trackEvent({
            event: 'filter_activate',
            filter: value
        });

        router.push({
            query: {
                ...route.query,
                [queryParam]: addToQueryString(route.query[queryParam], value)
            }
        });
    };

    const activateFilters = function(values) {
        let queryString = route.query[queryParam];

        values.forEach(value => {
            queryString = addToQueryString(queryString, value);

            useGtm()?.trackEvent({
                event: 'filter_activate',
                filter: value
            });
        });

        router.push({
            query: {
                ...route.query,
                [queryParam]: queryString
            }
        });
    };

    const deactivateFilter = function(value) {
        if (!isActive(value)) {
            return;
        }
        const queryString = removeFromQueryString(route.query[queryParam], value);

        const query = {
            ...route.query
        };

        if (!queryString) {
            delete query[queryParam];
        } else {
            query[queryParam] = queryString;
        }

        useGtm()?.trackEvent({
            event: 'filter_deactivate',
            filter: value
        });

        router.push({
            query
        });
    };

    const toggleFilter = function(value) {
        if (isActive(value)) {
            deactivateFilter(value);
        } else {
            activateFilter(value);
        }
    };

    const clearFilters = function() {
        const query = {
            ...route.query,
        };

        delete query[queryParam];

        router.push({
            query
        });
    };

    function addToQueryString(queryString, value) {
        // 0. Do nothing if the filter is already active
        if (isActive(value)) {
            return queryString;
        }

        // 1. No filters are active yet: add this one
        if (!queryString) {
            return value;
        }

        // 2. In all other cases, add it to the string
        return `${queryString},${value}`;
    }

    function removeFromQueryString(queryString, value) {
        // 0. Do nothing if the filter is not active
        if (!isActive(value)) {
            return queryString;
        }

        // 1. A single filter is active, which is this one: remove it
        if (!queryString.includes(',')) {
            return null;
        }

        // 2. Multiple filters are active, and this one is one of them: remove it
        if (queryString.includes(',')) {
            return queryString.split(',').filter(queryValue => parseInt(queryValue, 10) !== parseInt(value, 10)).join(',');
        }
    }


    return {
        activeValues,
        isActive,
        activateFilter,
        activateFilters,
        deactivateFilter,
        clearFilters,
        toggleFilter
    };
}
